.product-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-loader {
    width: 100%;
}

.m-flex {
    display: inline-flex;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.clk-src {
    background: none;
    border:none;
}

.footer_links {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;
    line-height: 18px;
    color: #000000;
    cursor: pointer;
}